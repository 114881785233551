@import url('https://fonts.googleapis.com/css?family=M+PLUS+1');

body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;

}

.logo-navbar {
  height: 80px;
}

@media screen and (max-width: 450px) {
  .card-flutuante {
    display: none;
  }

  .mobile-view {
    position: sticky;
  }

  .mobile-hidden {
    display: none !important;
  }

  .home-background-position {
    background-position: center;
  }

  .card-loading-width {
    width: 19.6rem;
  }
}

@media screen and (min-width: 451px) {

  .card-loading-width {
    width: auto;
  }

  .pc-hidden {
    display: none;
  }

  .card-flutuante {
    bottom: 0rem !important;
    float: none;
    width: 100.9% !important;
  }

  .mobile-view {
    display: none;
  }

  .react-multi-carousel-list {
    width: 100% !important;
  }

  .carousel-custom {
    min-width: 100% !important;
  }

  .carousel-custom li {
    margin-left: 0.5rem;
  }
}

@media (max-width: 991px) {
  .vehicle-card-info {
    width: 50% !important;
  }

  .nav-btns {
    color: #09162b !important;
    font-weight: 600 !important;
  }

  .banner {
    top: -2rem;
    height: 100%;
  }

  .navbar-toggler {
    color: #FFF !important;
  }

}


@media (min-width: 992px) {
  .banner {
    top: -5rem;
  }
  .info-conteiner {
    padding: 0px 75px 0px 75px;
  }

}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #bcbcbc;
}

.separator::before {
  margin-right: .25em;
}

.separator::after {
  margin-left: .25em;
}

::-webkit-scrollbar {
  /*display: none;*/
}

.scroll-bar-custom::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 15px;
  background-color: #D0D4CE;
}

.scroll-bar-custom::-webkit-scrollbar {
  height: 0.5rem;
  background-color: #D0D4CE;
  border-radius: 15px;
}

.scroll-bar-custom::-webkit-scrollbar-thumb {
  border-radius: 15px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #1d3a6c;
  height: 0.5rem;
}

.is-loading {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-container {
  width: 100%;
  z-index: 1001;
  position: fixed;
  padding: 0px 25px 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  z-index: -1;
}

.default-custom-color {
  color: #1d3a6c !important;
  background-color: #1d3a6c !important;
}

.btn-carregar-mais {
  font-size: 1.1rem !important;
  border-radius: 15px !important;
  color: #f7f7f7 !important;
  text-transform: none !important;
}

.btn-acessar-veiculo {
  text-transform: uppercase !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  background-color: #1d3a6c !important;
  color: #fff !important;
}

.banner-container {
  padding: 0 !important;
  height: 65vh;
  overflow: hidden;
  background-image: url(img/Osmani-Foto.jpg);
  background-size: cover;
  border-image: fill 0 linear-gradient(rgba(19, 15, 255, 0.2), #09162b);
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.banner-content h1 {
  font-weight: 400;
  color: #FFF;
}

.banner-content h4 {
  margin-top: 1rem;
  font-weight: 200;
  color: #FFF;
}

.filters-container {
  padding: 5px;
  background-color: #FFF;
  border-radius: 7px;
  border: 0.1px solid #DDDA;
}

.filter-description {
  padding: 1rem;
}

.filter-description-title {
  font-size: 1.2rem !important;
  font-weight: 600 !important;

}

.filter-description-tip {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.filter-object {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.filter-object input,
select {
  padding: 5px;
  border: 0px !important;
  outline: 0px !important;
  appearance: none !important;
  background-color: #FFF0 !important;
}

.filter-object textarea:focus,
input:focus {
  outline: none !important;
}

.filter-btn {
  display: flex !important;
  padding: 5px !important;
  transition: 0.3s !important;
}

.filter-btn a {
  width: 100% !important;
  height: auto !important;
  font-size: 1.2rem !important;
  background-color: #1d3a6c;
  color: #FFF !important;
  border-radius: 20px !important;
  text-align: center;
  vertical-align: center;
  padding: 20px;
  transition: 0.3s !important;
}

.filter-btn a:hover {
  transform: scale(1.05);
  background-color: #1369ff !important;
}

.filters {
  margin-top: -3rem !important;
  overflow: hidden !important;
  justify-content: center;
  align-items: center;
}

.swiper-container {
  height: 15vh;
}

.slide-item {
  margin: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
}

.slide-item img {
  height: 100%;
  object-fit: contain;
}

.logo-carroussel-fade {
  background-image: linear-gradient(to left, #c0c0c0 10%, #FFF0 40%, #FFF0 60%, #c0c0c0 90%);
  margin-top: 1rem;
  height: 9rem;
  width: 100%;
  position: absolute;
  z-index: 1001;
}

.gradient-bg {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgb(43, 66, 149, 0)), var(--img);
  object-fit: cover;
  height: 300px;
  right: 0;
}

.wpp-btn {
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #075E54;
  border-radius: 5px;
}

.wpp-btn:hover {
  background-color: #25D366;
}

.product-card-top {
  width: 100%;
  height: auto;
}

.product-card-top img {
  width: 100%;
  height: auto;
  border-radius: 0 0 5px 5px !important;
}

.vehicle-card {
  padding: 0px !important;
  margin: 0px !important;
  height: 12rem;
  border-radius: 15px 15px 0px 0px !important;
}

.vehicle-card-image {
  position: relative;
  /* Essencial para posicionar corretamente o pseudo-elemento */
  background-size: cover;
  background-position: left bottom;
  border-radius: 10px !important;
  width: 100%;
  height: 30vh;
  /* Exemplo de altura, ajuste conforme a necessidade */
}

.vehicle-card-image::before {
  content: '';
  /* Necessário para criar o pseudo-elemento */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(29, 58, 190, 0.7) -15%, rgba(29, 58, 108, 0) 15%);
  border-radius: 10px !important;
  z-index: 1;
  /* Garante que o overlay fique acima da imagem de fundo */
}

.wppBtnCheckout {
  border: none !important;
  border-radius: 15px !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  font-family: 'Gotham', sans-serif !important;
  text-transform: initial !important;
  background-color: #00b74a !important;
  margin-top: 25px !important;
  padding: 15px !important;
}


.vehicle-card img {
  width: 100%;
  height: auto;
}

.card-hover {
  transition: 0.3s;
}

.card-hover:hover {
  z-index: 10;
}

.vehicle-card-info {
  padding: 2px;
  font-size: 0.8rem;
  font-weight: 400;
  color: #1e1e1e;
}

.veiculo-slide {
  object-fit: contain;
  width: 100%;
  height: 50%;
}

.veiculo-slide img {
  object-fit: contain;
  width: 100%;
}

.swiper-veiculo-container {
  height: auto;
}

.navbar-main {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  background-color: #1d3a6c;
  margin: 0.5rem 0.3rem;
  border-radius: 10px;
}

.nav-btns {
  vertical-align: text-bottom !important;
  border-radius: 7px;
  padding: 7px;
  color: #FFF !important;
  transition: 0.3s;
  box-shadow: none !important;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 0.1rem solid #1369ff25 !important;
  margin-right: 5px;

}

.nav-btns:hover {
  border: 0.1rem solid #1369ff !important;
  transform: scale(1.1);
}

.nav-btns svg {
  color: #FFF;
  height: auto;
  width: 1.4rem;
}

.thumbs-holder {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  border-radius: 10px !important;
}

.thumbs-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
}

.thumbs-buttons button {
  border-radius: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #1369ff20;
}

.thumbs-buttons button:hover {
  background-color: #1369ff50;
  color: #1369ff;
}

.thumbs-img {
  display: flex;
  justify-content: center;
  height: 100%;
}

.preview-gallery {
  justify-content: center;
}

.title-veiculo {
  overflow: hidden !important;
  white-space: unset !important;
}

.info-conteiner {
  padding: 0px 1rem 0px 1rem;
}